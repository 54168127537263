<template>
  <div v-if="isLoading">
    <div class="text-center text-danger screen-center">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div v-else>
    <div class="breadcrumb">
      <h1>Nueva Aseguradora</h1>
    </div>
    <div class="row mb-2">
      <div class="col-sm-12">
        <div class="card text-left">
          <form @submit.prevent="guardarAseguradora">
            <div class="card-header">
              <div class="col-sm-5">
                <button type="submit"
                 class="btn bg-custom-green mr-2 rounded"
                 :disabled="buttonDisabled">
                 Guardar
                </button>
                <router-link :to="{ name: 'aseguradora-index' }">
                  <button class="btn btn-white border border-light rounded">
                    Regresar
                  </button>
                </router-link>
              </div>
            </div>
            <div class="card-body pb-0">
              <div class="row mb-4">
                <div class="col-sm-6">
                  <div class="row mb-2">
                    <div class="col-sm-3 pr-0 text-right">
                      <label class="col-form-label">Cliente:
                        <span class="text-danger">*</span>
                      </label>
                    </div>
                    <div class="col-sm-7">
                      <multiselect
                        v-model="clienteSelected"
                        :options="clientes"
                        placeholder=""
                        label="nombre"
                        track-by="id"
                        select-label=""
                        deselect-label="X">
                        <span slot="caret" v-if="!(clienteSelected===null)"></span>
                        <span slot="noResult">Sin Resultados</span>
                      </multiselect>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-sm-3 pr-0 text-right">
                      <label class="col-form-label">
                        Contacto 1:
                      </label>
                    </div>
                    <div class="col-sm-7">
                      <input
                        type="text"
                        v-model="contacto1"
                        class="form-control text-center"
                        autocomplete="off"
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-3 pr-0 text-right">
                      <label class="col-form-label">
                        Contacto 2:
                      </label>
                    </div>
                    <div class="col-sm-7">
                      <input
                        type="text"
                        v-model="contacto2"
                        class="form-control text-center"
                        autocomplete="off"
                      >
                    </div>
                  </div>
                <div class="row mt-2 justify-content-center">
                  <div class="col-sm-6 pr-0 text-left">
                    <div class="form-group form-check">
                      <input type="checkbox" class="form-check-input" id="exampleCheck1">
                      <label class="form-check-label" for="exampleCheck1">
                        No imprime Diagnóstico
                      </label>
                      <div class="icon-container ml-2">
                        <svg @mouseover="hover = true" @mouseleave="hover = false" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16" height="16" viewBox="0,0,256,256"
                            style="fill:#FFFFFF;">
                            <g fill="#e91414" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(5.12,5.12)"><path d="M25,2c-12.6907,0 -23,10.3093 -23,23c0,12.69071 10.3093,23 23,23c12.69071,0 23,-10.30929 23,-23c0,-12.6907 -10.30929,-23 -23,-23zM25,4c11.60982,0 21,9.39018 21,21c0,11.60982 -9.39018,21 -21,21c-11.60982,0 -21,-9.39018 -21,-21c0,-11.60982 9.39018,-21 21,-21zM25,11c-1.65685,0 -3,1.34315 -3,3c0,1.65685 1.34315,3 3,3c1.65685,0 3,-1.34315 3,-3c0,-1.65685 -1.34315,-3 -3,-3zM21,21v2h1h1v13h-1h-1v2h1h1h4h1h1v-2h-1h-1v-15h-1h-4z"></path></g></g>
                          </svg>
                        <div class="info-message" :class="{ 'visible': hover }">Esto solo afecta al imprimir en internacion/C E en receta</div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <div class="col-sm-6">
                  <div class="row mb-2">
                    <div class="col-sm-4 pr-0 text-right">
                      <label class="col-form-label">
                        Nit:
                      </label>
                    </div>
                    <div class="col-sm-5">
                      <input
                        type="text"
                        :value="nit"
                        class="form-control text-center"
                        autocomplete="off"
                        disabled
                      >
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-sm-4 pr-0 text-right">
                      <label class="col-form-label">
                        Celular:
                      </label>
                    </div>
                    <div class="col-sm-2">
                      <multiselect
                        v-model="paisSelected"
                        :options="paises"
                        :allow-empty="false"
                        :show-labels="false"
                        :searchable="false"
                        :close-on-select="true"
                        label="descripcion"
                        track-by="descripcion"
                        @input="setCodigoPais">
                        <template slot="singleLabel" slot-scope="props">
                          <img class="option__image"
                            :src="props.option.img"
                            :alt="props.option.descripcion" width="26px">
                        </template>
                        <template slot="option" slot-scope="props">
                          <img class="option__image"
                            :src="props.option.img"
                            :alt="props.option.descripcion" width="26px">
                        </template>
                      </multiselect>
                    </div>
                    <div class="col-sm-3 pl-0 py-1">
                      <input type="text"
                        class="form-control"
                        :placeholder="'+'+codigoPais"
                        v-model="celular1"
                        autocomplete="off"
                        @keydown="validarCaracter"
                        @blur="reValidarCelular1">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4 pr-0 text-right">
                      <label class="col-form-label">
                        Celular:
                      </label>
                    </div>
                    <div class="col-sm-2">
                      <multiselect
                        v-model="paisSelected2"
                        :options="paises"
                        :allow-empty="false"
                        :show-labels="false"
                        :searchable="false"
                        :close-on-select="true"
                        label="descripcion"
                        track-by="descripcion"
                        @input="setCodigoPais2">
                        <template slot="singleLabel" slot-scope="props">
                          <img class="option__image"
                            :src="props.option.img"
                            :alt="props.option.descripcion"
                            width="26px">
                        </template>
                        <template slot="option" slot-scope="props">
                          <img class="option__image"
                            :src="props.option.img"
                            :alt="props.option.descripcion"
                            width="26px">
                        </template>
                      </multiselect>
                    </div>
                    <div class="col-sm-3 pl-0 py-1">
                      <input type="text"
                        class="form-control"
                        :placeholder="'+'+codigoPais2"
                        v-model="celular2"
                        autocomplete="off"
                        @keydown="validarCaracter"
                        @blur="reValidarCelular2"
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4 pr-0 text-right">
                      <label class="col-form-label">
                        Tipo Seguro:
                      </label>
                    </div>
                    <div class="col-sm-5">
                      <multiselect
                          v-model="tipoSeguroSelected"
                          :options="tipoSeguro"
                          placeholder=""
                          label="nombre"
                          track-by="id"
                          select-label=""
                          deselect-label="X">
                          <span slot="noResult">Sin Resultados</span>
                        </multiselect>
                    </div>
                  </div>
                  <div v-if="tipoSeguroSelected.id === 2">

                    <div class="row justify-content-center">
                    <div class="col-lg-6 col-md-4 col-sm-6">
                      <div
                          class="p-1 mb-2"
                          style="border-radius: 5px; border: 1px solid orange;"
                        >
                          <div class="row align-items-center">
                            <div class="col-3">
                              <label style="font-size: 0.7rem; margin: 0;">Límite:</label>
                            </div>
                            <div class="col-5">
                              <input
                                type="number"
                                class="form-control form-control-sm"
                                placeholder="Límite"
                                v-model="limiteMonetario"
                                style="font-size: 0.7rem;"
                              />
                            </div>
                            <div class="col-3">
                              <label style="font-size: 0.7rem; margin: 0;">{{ moneda }}</label>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
              <!-- Fin Tabla -->
            </div>
          </form>
          <sectionTabServicesAndProductos
            v-if="servicios.length > 0 && categoriaAseguradoras.length > 0 || situaciones "
            :servicios="servicios"
            :categoriaAseguradoras="categoriaAseguradoras"
            :situaciones="situaciones"
            :listaTabla="[]"
            :listaTablaProductos="[]"
            :datosHabitacion="[]"
            :listaTablaCantidad = "[]"
            :edit="false"
            :tipoSeguroSelected="tipoSeguroSelected"
            :moneda="moneda"
            @update-lista-tabla="actualizarListaTabla"
            @update-lista-tabla-productos="actualizarListaTablaProductos"
            @update-datos-habitacion="actualizarDatosHabitacion"
            @update-lista-tabla-cantidad="actualizarListaTablaCantidad"
          />
        </div>
    </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import VueNumeric from 'vue-numeric';
import axios from '../../common/axiosERP';
import { util } from '../../../plugins/util';
import sectionTabServicesAndProductos from '../components/sectionTabServicesAndProductos.vue';

export default {
  name: 'create',
  components: {
    Multiselect,
    // eslint-disable-next-line vue/no-unused-components
    VueNumeric,
    sectionTabServicesAndProductos,
  },
  data() {
    return {
      isLoading: true,
      contacto1: '',
      contacto2: '',
      codigoPais: '',
      codigoPais2: '',
      paisSelected: null,
      paisSelected2: null,
      paises: [],
      clienteSelected: null,
      clientes: [],
      serviciosSelected: null,
      servicios: [],
      celular1: '',
      celular2: '',
      coaseguro: 0,
      seguro: 0,
      listaTabla: [],
      situaciones: null,
      buttonDisabled: false,
      categoriaSelected: null,
      categoriaAseguradoras: [],
      modoEdicionTabla: false,
      textButtonForm: 'Agregar',
      selectedId: null,
      selectedCategoryId: null,
      hover: false,
      productoSelected: null,
      productos: [],
      fieldsProducto: [
        {
          key: 'numero', label: 'N°', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '5%' },
        },
        {
          key: 'editar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'eliminar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'codigo', label: 'Codigo', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '5%' },
        },
        {
          key: 'producto', label: 'Nombre', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '28%' },
        },
        {
          key: 'subgrupo', label: 'Subgrupo', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '19%' },
        },
        {
          key: 'coa_seguro', label: 'CoaSeguro', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '9%' },
        },
        {
          key: 'seguro', label: 'Seguro', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '9%' },
        },
        {
          key: 'total', label: 'Total', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '9%' },
        },
        {
          key: 'estado', label: 'Estado', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '10%' },
        },
      ],
      listaTablaProductos: [],
      textButtonFormProd: 'AgregarProducto',
      isArchivoActivo: false,
      isButtonDisabled: false,
      page: 1,
      perPage: 100,
      totalRegistro: 0,
      cargarProd: false,
      productosFiltrados: [],
      filtro: '',
      tipoSeguro: [
      ],
      tipoSeguroSelected: { id: 1, nombre: 'normal', estado: 1 },
      moneda: '',
      limiteMonetario: null,
      listaTablaCantidad: [],
    };
  },
  async created() {
    await this.cargarDatosIniciales();
    /* eslint-disable prefer-destructuring */
    this.paisSelected = this.paises[0];
    this.paisSelected2 = this.paises[0];
    this.codigoPais = this.paises[0].codigo_telefono;
    this.codigoPais2 = this.paises[0].codigo_telefono;
    this.listaTabla = [];
  },
  methods: {
    async cargarDatosIniciales() {
      this.isLoading = true;
      try {
        const response = await axios.get('/clinic/insurer/create');
        const CATEGORIAS_ASEGURADORA = response.data.data.categoria_aseguradoras;
        const { paises } = response.data.data;
        const { clientes } = response.data.data;
        const services = response.data.data.tipo_servicios;
        const { situaciones } = response.data.data;
        this.cargarPaises(paises);
        this.clientes = clientes;
        this.categoriaAseguradoras = CATEGORIAS_ASEGURADORA;
        this.tipoSeguro = response.data.data.tipo_seguro.map((tipo) => {
          if (tipo.id === 3) {
            return {
              ...tipo,
              $isDisabled: true,
            };
          }
          return {
            ...tipo,
            $isDisabled: false,
          };
        });
        this.moneda = response.data.data.moneda;
        this.cargarServicios(services);
        this.cargarSituaciones(situaciones);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    async persistirFormulario(request) {
      this.buttonDisabled = true;
      try {
        const response = await axios.post('/clinic/insurer', request);
        util.showNotify(response.data.message, 'success');
        this.redirect({ name: 'aseguradora-index' });
      } catch (error) {
        const FALLO_VALIDACION = 422;
        if (error.response.status !== FALLO_VALIDACION) {
          util.showNotify(error.response.data.message, 'error');
        } else {
          Object.entries(error.response.data.data).forEach(([, mensajes]) => {
            mensajes.forEach((texto) => util.showNotify(texto, 'error'));
          });
        }
        this.buttonDisabled = false;
      }
    },
    guardarAseguradora() {
      if (this.clienteSelected === null) {
        util.showNotify('Elija un Cliente ', 'warn');
        return;
      }
      if (this.listaTabla.length === 0) {
        util.showNotify('Debe agregar por lo menos 1 Servicio', 'warn');
        return;
      }
      const contactos = [];
      if (this.contacto1 !== '') {
        contactos.push({
          nombre: this.contacto1,
          telefono: this.celular1,
          pais_id: this.paisSelected.id,
        });
      }
      if (this.contacto2 !== '') {
        contactos.push({
          nombre: this.contacto2,
          telefono: this.celular2,
          pais_id: this.paisSelected2.id,
        });
      }
      const aseguradoraServicios = [];
      this.listaTabla.forEach((item) => {
        const status = item.estado === false ? 0 : 1;
        const obj = {
          id: item.id,
          tipo_servicio_id: item.tipo_servicio_id,
          categoria_aseguradora_id: item.categoria_aseguradora_id,
          situacion_id: item.situacion_id,
          comentario: null,
          deleted: item.deleted,
          coa_seguro: item.coa_seguro,
          seguro: item.seguro,
          estado: status,
        };
        aseguradoraServicios.push(obj);
      });
      const aseguradoraProductos = [];
      this.listaTablaProductos.forEach((item) => {
        const status = item.estado ? 'true' : 'false';
        const obj = {
          tipo_producto_id: item.tipo_producto_id,
          categoria_aseguradora_id: item.categoria_aseguradora_id,
          coa_seguro: item.coa_seguro,
          seguro: item.seguro,
          situacion_id: item.situacion_id,
          estado: status,
        };
        aseguradoraProductos.push(obj);
      });
      const aseguradoraSalaCama = [];
      this.datosHabitacion.forEach((item) => {
        const obj = {
          sala_cama_id: item.sala_cama_id,
          seguro: item.seguro,
          situacion_id: item.situacion_id,
        };
        aseguradoraSalaCama.push(obj);
      });
      const aseguradoraServiciosCantidad = [];
      this.listaTablaCantidad.forEach((item) => {
        const status = item.estado === false ? 0 : 1;
        const obj = {
          id: item.id,
          tipo_servicio_id: item.tipo_servicio_id,
          categoria_aseguradora_id: item.categoria_aseguradora_id,
          situacion_id: item.situacion_id,
          comentario: null,
          deleted: item.deleted,
          coa_seguro: 0,
          seguro: 0,
          estado: status,
          cantidad_maxima_anual: item.cantidad_maxima_anual,
        };
        aseguradoraServiciosCantidad.push(obj);
      });
      const request = {
        aseguradora: {
          nit: this.clienteSelected.nit,
          persona_id: this.clienteSelected.id,
        },
        contactos,
        aseguradora_servicios: aseguradoraServicios,
        aseguradora_productos: aseguradoraProductos,
        aseguradora_sala_cama: aseguradoraSalaCama,
        limite_monetario: parseFloat(this.limiteMonetario),
        aseguradora_servicios_cantidad: aseguradoraServiciosCantidad,
      };
      this.persistirFormulario(request);
    },
    cargarPaises(lista) {
      this.paises = lista;
    },
    cargarServicios(lista) {
      this.servicios = lista;
    },
    cargarSituaciones(lista) {
      this.situaciones = lista;
    },
    setCodigoPais() {
      this.codigoPais = this.paisSelected.codigo_telefono;
    },
    setCodigoPais2() {
      this.codigoPais2 = this.paisSelected2.codigo_telefono;
    },
    redirect(path) {
      this.$router.push(path);
    },
    validarCaracter(event) {
      const validKeys = new RegExp('^[0-9]*$');
      const controlKeys = ['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight', 'KeyX', 'KeyC', 'KeyV', 'Home', 'End', 'Tab'];
      if (controlKeys.includes(event.code)) {
        switch (event.code) {
          case 'KeyX':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          case 'KeyC':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          case 'KeyV':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          default:
            break;
        }
        return;
      }
      if (!validKeys.test(event.key)) {
        event.preventDefault();
      }
    },
    reValidarCelular1() {
      if (this.celular1 === '') {
        return;
      }
      const validKeys = new RegExp('^[0-9]*$');
      if (!validKeys.test(this.celular1)) {
        this.celular1 = '';
      }
    },
    reValidarCelular2() {
      if (this.celular2 === '') {
        return;
      }
      const validKeys = new RegExp('^[0-9]*$');
      if (!validKeys.test(this.celular2)) {
        this.celular2 = '';
      }
    },
    actualizarListaTabla(nuevaLista) {
      this.listaTabla = nuevaLista;
    },
    actualizarListaTablaProductos(nuevaLista) {
      this.listaTablaProductos = nuevaLista;
    },
    actualizarDatosHabitacion(nuevaLista) {
      this.datosHabitacion = nuevaLista;
    },
    actualizarListaTablaCantidad(nuevaLista) {
      this.listaTablaCantidad = nuevaLista;
    },
  },
  computed: {
    nit() {
      if (this.clienteSelected !== null) {
        return this.clienteSelected.nit;
      }
      return '';
    },
  },
};
</script>

<style>
.btn-skyblue
{
  color: #fff;
  background-color: #61c6f2;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
.icon-container {
  position: relative;
  display: inline-block;
}
.info-message {
  display: none;
  position: absolute;
  top: -30px;
  right: 50%;
  transform: translateX(50%);
  background-color: rgba(36, 33, 33, 0.7);
  color: white;
  padding: 0.5em 1em;
  border-radius: 0.5em;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
  text-align: center;
  z-index: 1;
}
.visible {
  display: block;
}
.green{
  background-color: #76FF7A;
  color: white;
}
.nav-tabs .nav-item .nav-link:not(.disabled) {
  color: orange;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
